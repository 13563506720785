<template>
  <div class="pt-10" style="position: relative">
    <CoveredLoader v-if="loading" />
    <v-card class="my-billing-card">
      <v-card-title class="pa-0 ma-0">
        <h2 class="font-family-raleway-bold">{{ $t("billing_page.title") }}</h2>
      </v-card-title>
      <div class="mt-4 request-refund-info" v-if="infoStatus">
        <div class="justify-space-between d-flex pr-5 pl-10 pt-5">
          <h3 class="d-flex align-center">
            <img :src="infoIcon" alt="" width="22" height="22" />
            <span class="ml-2">
              {{ $t("billing_page.refund_request_successfully_submitted") }}
            </span>
          </h3>
          <v-btn icon @click="infoStatus = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          style="justify-content: center; width: 100%"
          class="pb-10 px-10 pt-2"
        >
          <div>
            <strong>{{ $t("billing_page.review_process") }}</strong
            >:
            {{ $t("billing_page.review_process_description") }}
            <br />
            <strong>{{ $t("billing_page.communication") }}</strong
            >: {{ $t("billing_page.communication_description") }}
            <br />
            <strong>{{ $t("billing_page.processing_time") }}</strong
            >: {{ $t("billing_page.processing_time_description") }}
            <br />
            <strong>{{ $t("billing_page.need_assistance") }}</strong>
            {{ $t("billing_page.need_assistance_description") }}
            <br />
            {{ $t("billing_page.value_choice") }}
          </div>
        </div>
      </div>
      <v-row class="mt-2">
        <v-col md="6">
          <div
            class="d-flex justify-space-between align-center"
            style="height: 48px; margin-bottom: 16px"
          >
            <h3 class="font-family-raleway-medium">
              {{ $t("billing_page.breakdown") }}
            </h3>
            <v-col
              md="4"
              class="elevation-0 d-flex align-center"
              style="height: 100%"
            >
              <v-select
                background-color="#F5F9FC"
                :items="itemsSelect"
                v-model="filterDate"
                class="select pt-6"
                height="48"
                dense
                solo
                flat
              />
            </v-col>
          </div>
          <div>
            <div class="pr-4">
              <div class="d-flex justify-space-between">
                <span class="font-raleway-regular">{{
                  $t("billing_page.total_ad_spend")
                }}</span>
                <strong class="font-family-raleway-bold nowrapText"
                  >$
                  {{
                    getRoundedNumbers(getBillingBreakdown.totalAdSpend)
                  }}</strong
                >
              </div>
              <div class="d-flex justify-space-between my-3">
                <span class="font-raleway-regular">{{
                  $t("billing_page.ad_spend_fee")
                }}</span>
                <strong class="font-family-raleway-bold nowrapText"
                  >$
                  {{
                    getRoundedNumbers(getBillingBreakdown.totalAdSpendFeee)
                  }}</strong
                >
              </div>
              <div class="d-flex justify-space-between my-3">
                <span class="font-raleway-regular">{{
                  $t("billing_page.subscription_fee")
                }}</span>
                <strong class="font-family-raleway-bold nowrapText"
                  >$
                  {{
                    getRoundedNumbers(getBillingBreakdown.totalSubScriptionFee)
                  }}</strong
                >
              </div>
              <div
                class="d-flex justify-space-between font-family-raleway-bold"
              >
                <strong>{{ $t("billing_page.total_charges") }}</strong>
                <h2 class="font-family-raleway-bold">
                  ${{ getRoundedNumbers(getTotalBilling) }}
                </h2>
              </div>
            </div>
            <div class="d-flex align-center justify-space-between mt-2 fld">
              <div class="d-flex ad-credits-box justify-space-between">
                <span>{{ $t("billing_page.ad_credits") }}</span>
                <h3>${{ adCreditsBalance }}</h3>
              </div>
              <v-btn
                class="button text-capitalize px-5"
                height="48"
                @click="clickRefundButton"
                :loading="isRefundinfReqeust"
              >
                {{ $t("request_refund") }}
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col md="6">
          <change-plan-section
            :paymentMethodId="paymentMethodId"
            @showWarningText="isHaveWarningText = true"
          />
        </v-col>
      </v-row>
      <v-divider class="my-7" />
      <div>
        <billing-information-section
          :stripePaymentMethods="paymentMethods"
          :paymentMethodId="paymentMethodId"
          :isHaveWarningText="isHaveWarningText"
          @disableWarningText="isHaveWarningText = false"
          @setDefaultPayments="getUserInfoAndSetPaymentMethods"
        />
      </div>
    </v-card>
    <v-card class="profile-settings-card mt-5 pb-15">
      <InvoiceSection />
    </v-card>
  </div>
</template>

<script>
import { authService } from "@/services/auth";
import moment from "moment";
import { plansService } from "@/services/plans";
import infoIcon from "@/assets/icon/info.svg";

export default {
  name: "Billing",
  components: {
    BillingInformationSection: () => import("@/components/Billing/BillingInfosmationSection"),
    ChangePlanSection: () => import("@/components/Billing/ChangePlanSection"),
    CoveredLoader: () => import("@/components/CoveredLoader"),
    InvoiceSection: () => import("@/components/Account/user-settings/InvoiceSection"),
  },
  data() {
    return {
      infoIcon,
      itemsSelect: [""],
      filterDate: "",
      plan: {
        type: "",
        budget: 0,
      },
      loading: false,
      isHaveWarningText: false,
      isRefundinfReqeust: false,
      isRefunded: false,
      infoStatus: false,
      stripePaymentMethod: {},
      adCreditsBalance: 0,
      paymentMethodId: "",
      user: {},
      paymentMethods: [],
      getBillingBreakdown: {
        totalAdSpend: 0,
        totalAdSpendFeee: 0,
        totalSubScriptionFee: 0,
      },
    };
  },
  async created() {
    this.loading = true;
    await this.getUserInfoAndSetPaymentMethods();
    const currentDate = moment().format("MMMM YYYY");
    const currentYear = moment().format("YYYY");
    this.filterDate = currentDate;
    this.itemsSelect = [currentDate];
    const currentMonth = moment().month() + 1;
    const response = await authService.getBillingBreakdown(
      currentMonth,
      currentYear
    );
    this.getBillingBreakdown = response.data;
    this.loading = false;
  },
  methods: {
    async getUserInfoAndSetPaymentMethods() {
      const { data } = await authService.getUserInfo();
      const { stripePaymentMethods, balance } = data;
      this.adCreditsBalance = balance;
      this.user = data;
      if (stripePaymentMethods.length > 0) {
        this.paymentMethods = stripePaymentMethods;
        const findDefaultCard = stripePaymentMethods.find(
          (item) => item.default === true
        );
        this.stripePaymentMethod = findDefaultCard;
        this.paymentMethodId = findDefaultCard.pM_ID;
      }
    },
    getRoundedNumbers(num) {
      return Math.round(num * 100) / 100;
    },
    async clickRefundButton() {
      this.isRefundinfReqeust = true;
      await plansService.requestRefund();
      this.isRefunded = true;
      this.infoStatus = true;
      this.isRefundinfReqeust = false;
      this.$notify({
        group: "foo",
        type: "success",
        title: "Refund Request Initiated!",
        text: this.$t("billing_page.request_refund_text"),
        duration: 10000,
      });
    },
  },
  computed: {
    getTotalBilling() {
      const { totalAdSpend, totalAdSpendFeee, totalSubScriptionFee } =
        this.getBillingBreakdown;
      return totalAdSpend + totalAdSpendFeee + totalSubScriptionFee;
    },
  },
};
</script>

<style scoped lang="scss">
.my-billing-card {
  padding: 40px;
  box-shadow: none !important;
  border-radius: 12px;
  @media screen and (max-width: 600px) {
    padding: 20px !important;
  }
}
.profile-settings-card {
  padding: 40px 40px 20px 40px;
  box-shadow: none !important;
  border-radius: 12px;
  @media screen and (max-width: 600px) {
    padding: 20px 20px;
  }
  //@media screen and (max-width: 450px) {
  //  padding: 20px 10px;
  //}
}

.billing-card {
  padding-top: 40px;
}
.chosen-plan-box {
  position: relative;
  border-radius: 10px;
  padding: 20px;
  background-color: #f5f9fc;
}
.ad-credits-box {
  border-radius: 16px;
  padding: 12px 16px;
  background-color: #f5f9fc;
  width: 260px;
}

@media screen and (max-width: 450px) {
  .nowrap {
    white-space: nowrap;
  }
  .button {
    width: 100%;
  }
  span {
    width: 100%;
  }
}
.request-refund-info {
  background-color: #f5f9fc;
  border-radius: 10px;
  font-family: "Raleway light", sans-serif;
}
</style>
